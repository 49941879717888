<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <label for="suggested" class="form-label">* Actividad Sugerida</label>
        <select
          class="form-select text-capitalize"
          name="suggested"
          v-model="suggestedSelected"
          required
        >
          <option
            v-for="suggested in suggestedList"
            v-bind:value="suggested"
            :key="suggested"
            >{{
              suggested
                .toLowerCase()
                .split("_")
                .join(" ")
            }}</option
          >
        </select>
        <div class="invalid-feedback">
          Por favor seleccione una actividad sugerida
        </div>
      </div>
      <div class="mb-3">
        <label for="origin" class="form-label">* Origen del Reporte</label>
        <select
          class="form-select text-capitalize"
          name="origin"
          v-model="originSelected"
          required
        >
          <option
            v-for="origin in originList"
            v-bind:value="origin"
            :key="origin"
            >{{
              origin
                .toLowerCase()
                .split("_")
                .join(" ")
            }}</option
          >
        </select>
        <div class="invalid-feedback">
          Por favor seleccione un origen del reporte
        </div>
      </div>
      <div class="mb-3">
        <label for="target" class="form-label">* Objetivo</label>
        <select
          class="form-select"
          name="target"
          v-model="targetSelected"
          required
        >
          <option
            v-for="target in targetList"
            v-bind:value="target._id"
            :key="target._id"
            >{{ target.name }}</option
          >
        </select>
        <div class="invalid-feedback">
          Por favor seleccione un objetivo
        </div>
      </div>
      <div class="mb-3">
        <label for="standard" class="form-label">* Estándar</label>
        <select
          class="form-select text-capitalize"
          name="standard"
          v-model="standardSelected"
          required
        >
          <option
            v-for="standard in standardList"
            v-bind:value="standard._id"
            :key="standard._id"
            >{{ standard.name }}</option
          >
        </select>
        <div class="invalid-feedback">
          Por favor seleccione un estándar
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('back')"
        >
          Anterior
        </button>
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('forward')"
        >
          Siguiente
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: [
    "branchID",
    "sectionName",
    "defaultData",
    "sectionPosition",
    "submitValidate",
    "submitName"
  ],
  data() {
    return {
      initDefault: true,
      mounted: false,
      suggestedList: [],
      originList: [],
      targetList: [],
      standardList: [],
      suggestedSelected: "",
      originSelected: "",
      targetSelected: "",
      standardSelected: "",
      errorMsg: ""
    };
  },
  mounted() {
    store.getData("target").then(targetList => {
      targetList.sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });
      targetList.forEach(target => {
        if (target.branch === this.branchID) {
          this.targetList.push(target);
        }
      });
    });
    store.getData("standard").then(standardList => {
      standardList.sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });
      this.standardList = standardList;
      this.mounted = true;
    });
    store.getData("schema").then(schemaData => {
      schemaData.forEach(schema => {
        if (schema.store === "summary") {
          this.suggestedList = schema.data.suggested.enum;
          this.originList = schema.data.origin.enum;
        }
      });
    });
  },
  methods: {
    submit() {
      this.$emit("submitForm", true);
    },
    next(event) {
      this.$emit("moveForm", event);
    }
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.suggested !== undefined && val.suggested !== null) {
              this.suggestedSelected = val.suggested;
            }
            if (val.origin !== undefined && val.origin !== null) {
              this.originSelected = val.origin;
            }
            if (val.target !== undefined && val.target !== null) {
              this.targetSelected = val.target;
            }
            if (val.standard !== undefined && val.standard !== null) {
              this.standardSelected = val.standard;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    submitValidate: function(val) {
      if (this.suggestedSelected === "") {
        this.errorMsg = "Por favor seleccione una actividad sugerida";
      } else if (this.originSelected === "") {
        this.errorMsg = "Por favor seleccione un origen de reporte";
      } else if (this.targetSelected === "") {
        this.errorMsg = "Por favor seleccione un objetivo";
      } else if (this.standardSelected === "") {
        this.errorMsg = "Por favor seleccione un estándar";
      } else {
        this.errorMsg = "";
      }

      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Summary Form 1"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
