<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div v-if="currentSupport === ''" class="mb-3">
        <label for="fileInput" class="form-label">Archivo</label>
        <input
          id="mainInputFile"
          class="form-control"
          type="file"
          name="fileInput"
          accept=".png,.jpg,.jpeg,.webp"
          @change="checkFile($event.target)"
          :disabled="currentSupport !== ''"
        />
      </div>
      <div class="mb-3">
        <label for="name" class="form-label">Nombre</label>
        <input
          type="text"
          class="form-control"
          name="name"
          :minlength="nameMin"
          :maxlength="nameMax"
          v-model="name"
          :disabled="currentSupport !== ''"
        />
      </div>
      <div v-if="currentSupport !== ''" class="mb-3 d-grid gap-2">
        <button
          @click="deleteSupport($route.params._id)"
          class="btn btn-danger"
          type="button"
        >
          Eliminar Soporte
        </button>
      </div>
      <input type="hidden" name="extension" v-model="extension" />
      <input type="hidden" name="fileSize" v-model="fileSize" />
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('back')"
        >
          Anterior
        </button>
        <button
          class="btn btn-primary shadow-primary"
          @click.prevent="submit()"
        >
          {{ submitName }}
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: ["sectionName", "defaultData", "submitName"],
  data() {
    return {
      initDefault: true,
      mounted: false,
      name: "",
      nameMin: 0,
      nameMax: 1,
      extension: "",
      enableExtensions: [],
      fileSize: "",
      currentSupport: ""
    };
  },
  mounted() {
    store.getData("schema").then(schemaData => {
      schemaData.forEach(schema => {
        if (schema.store === "file") {
          this.nameMin = schema.data.name.min;
          this.nameMax = schema.data.name.max;
          this.enableExtensions = schema.data.extension.enum;
        }
      });
      this.mounted = true;
    });
  },
  methods: {
    deleteSupport(summaryID) {
      Swal.fire({
        icon: "warning",
        title: "Eliminando soporte",
        html:
          "Por favor espere mientras se elimina el soporte</b> " +
          '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true
      });

      const data = {
        _id: summaryID,
        support: null
      };
      store.getData("summary", summaryID).then(summary => {
        store.deleteData("optima", "file", summary.support).then(() => {
          store.updateData("summary", [data], true, "optima").then(data => {
            Swal.fire({
              icon: "success",
              title: "Soporte Eliminado",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            this.name = "";
            this.currentSupport = "";
          });
        });
      });
    },
    submit() {
      this.$emit("submitForm", true);
    },
    next(event) {
      this.$emit("moveForm", event);
    },
    checkFile(fileInput) {
      const file = fileInput.files[0];

      let fileName = file.name.split(".");
      this.extension = fileName.pop();
      fileName = fileName.join(".");

      this.name = fileName;
      this.extension = this.extension.toUpperCase();

      this.fileSize = file.size;

      if (file.size > 2500000000) {
        Swal.fire({
          icon: "error",
          title: "Archivo no permitido",
          text: "El archivo no debe superar un tamaño de 4GB"
        }).then(() => {
          document.getElementById("mainInputFile").value = "";
          this.name = "";
        });
      }

      if (!this.enableExtensions.includes(this.extension)) {
        Swal.fire({
          icon: "error",
          title: "Archivo no permitido",
          text: "No se permite subir archivos con extensión: " + this.extension
        }).then(() => {
          document.getElementById("mainInputFile").value = "";
          this.name = "";
        });
      }
    }
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.support !== undefined && val.support !== null) {
              this.currentSupport = val.support;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    submitValidate: function(val) {
      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    },
    currentSupport: function(val) {
      if (val) {
        store.getData("file", val).then(file => {
          if (file) {
            this.name = file.name;
          } else {
            this.currentSupport = "";
          }
        });
      }
    }
  },
  name: "Document Main Form 1"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
