<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <label for="humanResource" class="form-label">* Recurso Humano</label>
        <textarea
          class="form-control"
          name="humanResource"
          rows="3"
          :minlength="humanResourceMin"
          :maxlength="humanResourceMax"
          required
          v-model="humanResource"
        ></textarea>
        <div class="invalid-feedback">
          Por favor ingrese un recurso humano
        </div>
      </div>
      <div class="mb-3">
        <label for="technicalResource" class="form-label"
          >* Recurso Técnico</label
        >
        <textarea
          class="form-control"
          name="technicalResource"
          rows="3"
          :minlength="technicalResourceMin"
          :maxlength="technicalResourceMax"
          required
          v-model="technicalResource"
        ></textarea>
        <div class="invalid-feedback">
          Por favor ingrese un recurso técnico
        </div>
      </div>
      <div class="mb-3">
        <label for="financialResource" class="form-label"
          >* Recurso Financiero</label
        >
        <div class="input-group">
          <span class="input-group-text">$</span>
          <input
            type="number"
            class="form-control"
            name="financialResource"
            v-model="financialResource"
            :min="financialResourceMin"
            :max="financialResourceMax"
            required
          />
          <div class="invalid-feedback">
            Por favor ingrese un recurso financiero
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('back')"
        >
          Anterior
        </button>
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('forward')"
        >
          Siguiente
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: ["sectionName", "sectionPosition", "submitValidate", "defaultData"],
  methods: {
    next(event) {
      this.$emit("moveForm", event);
    }
  },
  data() {
    return {
      initDefault: true,
      humanResource: "",
      humanResourceMin: 0,
      humanResourceMax: 1,
      technicalResource: "",
      technicalResourceMin: 0,
      technicalResourceMax: 1,
      financialResource: "",
      financialResourceMin: 0,
      financialResourceMax: 1,
      errorMsg: "",
      mounted: false
    };
  },
  mounted() {
    store.getData("schema").then(schemaData => {
      schemaData.forEach(schema => {
        if (schema.store === "summary") {
          this.humanResourceMin = schema.data.humanResource.min;
          this.humanResourceMax = schema.data.humanResource.max;
          this.technicalResourceMin = schema.data.technicalResource.min;
          this.technicalResourceMax = schema.data.technicalResource.max;
          this.financialResourceMin = schema.data.financialResource.min;
          this.financialResourceMax = schema.data.financialResource.max;
        }
      });
      this.mounted = true;
    });
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.humanResource !== undefined && val.humanResource !== null) {
              this.humanResource = val.humanResource;
            }
            if (
              val.technicalResource !== undefined &&
              val.technicalResource !== null
            ) {
              this.technicalResource = val.technicalResource;
            }
            if (
              val.financialResource !== undefined &&
              val.financialResource !== null
            ) {
              this.financialResource = val.financialResource;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    submitValidate: function(val) {
      if (this.humanResource === "") {
        this.errorMsg = "Por favor ingrese un recurso humano";
      } else if (this.technicalResource === "") {
        this.errorMsg = "Por favor ingrese un recurso técnico";
      } else if (this.financialResource === "") {
        this.errorMsg = "Por favor ingrese un recurso financiero";
      } else {
        this.errorMsg = "";
      }

      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Branch Form 2"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
