<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <label for="description" class="form-label"
          >* Descripción del Hallazgo</label
        >
        <textarea
          class="form-control"
          name="description"
          rows="3"
          :minlength="descriptionMin"
          :maxlength="descriptionMax"
          required
          v-model="description"
        ></textarea>
        <div class="invalid-feedback">
          {{ errorMsg }}
        </div>
      </div>
      <div class="mb-3">
        <label for="activity" class="form-label"
          >* Actividad a Desarrollar</label
        >
        <textarea
          class="form-control"
          name="activity"
          rows="3"
          :minlength="activityMin"
          :maxlength="activityMax"
          required
          v-model="activity"
        ></textarea>
        <div class="invalid-feedback">
          {{ errorMsg }}
        </div>
      </div>
      <div class="mb-3">
        <label for="limitDate" class="form-label">* Fecha Límite</label>
        <input
          type="date"
          class="form-control"
          name="limitDate"
          v-model="limitDate"
          required
        />
        <div class="invalid-feedback">
          Por favor ingrese una fecha límite
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('back')"
        >
          Anterior
        </button>
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('forward')"
        >
          Siguiente
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: ["sectionName", "sectionPosition", "submitValidate", "defaultData"],
  methods: {
    next(event) {
      this.$emit("moveForm", event);
    }
  },
  data() {
    return {
      initDefault: true,
      description: "",
      descriptionMin: 0,
      descriptionMax: 1,
      activity: "",
      activityMin: 0,
      activityMax: 1,
      errorMsg: "",
      limitDate: "",
      mounted: false
    };
  },
  mounted() {
    store.getData("schema").then(schemaData => {
      schemaData.forEach(schema => {
        if (schema.store === "summary") {
          this.descriptionMin = schema.data.description.min;
          this.descriptionMax = schema.data.description.max;
          this.activityMin = schema.data.activity.min;
          this.activityMax = schema.data.activity.max;
        }
      });
      this.mounted = true;
    });
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.description !== undefined && val.description !== null) {
              this.description = val.description;
            }
            if (val.activity !== undefined && val.activity !== null) {
              this.activity = val.activity;
            }
            if (val.limitDate !== undefined && val.limitDate !== null) {
              const d = new Date(val.limitDate);
              const year = d.getFullYear();
              let month = d.getMonth() + 1;
              if (month < 10) {
                month = "0" + month.toString();
              }
              let day = d.getDate();
              if (day < 10) {
                day = "0" + day.toString();
              }
              this.limitDate = year + "-" + month + "-" + day;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    description: function(val) {
      if (val.length < this.descriptionMin) {
        this.errorMsg = `La descripción debe tener al menos ${this.descriptionMin} caracteres`;
      } else if (val.length > this.descriptionMax) {
        this.errorMsg = `La descripción debe tener menos de ${this.descriptionMax} caracteres`;
      } else {
        this.errorMsg = "";
      }
    },
    activity: function(val) {
      if (val.length < this.activityMin) {
        this.errorMsg = `La actividad debe tener al menos ${this.activityMin} caracteres`;
      } else if (val.length > this.activityMax) {
        this.errorMsg = `La actividad debe tener menos de ${this.activityMax} caracteres`;
      } else {
        this.errorMsg = "";
      }
    },
    submitValidate: function(val) {
      if (this.description === "") {
        this.errorMsg = "Por favor ingrese una descripción";
      } else if (this.activity === "") {
        this.errorMsg = "Por favor ingrese una actividad";
      } else if (this.limitDate === "") {
        this.errorMsg = "Por favor ingrese una actividad";
      } else {
        this.errorMsg = "";
      }

      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Branch Form 2"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
