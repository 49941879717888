<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <label for="registrant" class="form-label"
          >* Quién Reporta la Novedad</label
        >
        <select
          class="form-select"
          name="registrant"
          v-model="registrantSelected"
        >
          <option v-bind:value="null">Anónimo</option>
          <option
            v-for="employee in employeeList"
            v-bind:value="employee._id"
            :key="employee.name"
            >{{ employee.name }}</option
          >
        </select>
        <div class="invalid-feedback">
          Por favor seleccione un empleado o anónimo
        </div>
      </div>
      <div class="mb-3">
        <label for="responsable" class="form-label"
          >* Responsable de la Actividad</label
        >
        <select
          class="form-select text-capitalize"
          name="responsable"
          v-model="responsableSelected"
          required
        >
          <option
            v-for="employee in employeeList"
            v-bind:value="employee._id"
            :key="employee.name"
            >{{ employee.name }}</option
          >
        </select>
        <div class="invalid-feedback">
          Por favor seleccione un empleado
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('forward')"
        >
          Siguiente
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Storage from "@/modules/storage";
import employee from "@/router/employee";

const store = new Storage();

export default {
  props: [
    "branchID",
    "sectionName",
    "defaultData",
    "sectionPosition",
    "submitValidate",
    "submitName"
  ],
  data() {
    return {
      initDefault: true,
      mounted: false,
      employeeList: [],
      registrantSelected: "",
      responsableSelected: "",
      errorMsg: ""
    };
  },
  mounted() {
    store.getData("employee").then(employeeList => {
      employeeList.sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });
      employeeList.forEach(employee => {
        if (employee.branch === this.branchID) {
          this.employeeList.push(employee);
        }
      });
      this.mounted = true;
    });
  },
  methods: {
    submit() {
      this.$emit("submitForm", true);
    },
    next(event) {
      this.$emit("moveForm", event);
    }
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.registrant !== undefined && val.registrant !== null) {
              this.registrantSelected = val.registrant;
            }
            if (val.responsable !== undefined && val.responsable !== null) {
              this.responsableSelected = val.responsable;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    submitValidate: function(val) {
      if (this.responsableSelected === "") {
        this.errorMsg = "Por favor seleccione un empleado";
      } else {
        this.errorMsg = "";
      }

      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Summary Form 1"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
